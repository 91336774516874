<template>
  <div class="section">
    <div class="container home" v-if="true">
      <br />
      <br />
      <br />
      <nav class="breadcrumb is-xlarge" aria-label="breadcrumbs">
        <ul>
          <li class="projectName">
            <router-link
              :to="{
                path: '/' + $route.params.bot + '/manage',
              }"
              >{{ $route.params.bot }}
            </router-link>
          </li>
          <li class="is-active" @click="copyGenerator($route.params.generator)">
            <b-tooltip
              :delay="500"
              multilinedNO
              position="is-top"
              type="is-dark"
              :label="copied == $route.params.generator ? 'copied!' : 'copy function name'"
            >
              <a>
                {{ $route.params.generator }} Ideas (Studio, generate)
                <span v-if="generator && generator.emoji" class="emoji"> &nbsp; {{ generator.emoji }} </span>
              </a>
            </b-tooltip>
          </li>
        </ul>
      </nav>

      <p>
        <router-link
          :to="{
            path: '/' + $route.params.bot + '/endpoints?method=' + 'get',
          }"
          active-class="is-active"
          exact
          style="float: left; padding-right: 5px"
        >
          <b-field>
            <b-tag rounded class="is-success" type=" is-light">253 ideas{{ generator.method }}</b-tag>
          </b-field>
        </router-link>
      </p>
      <p @click="copyGenerator(generator.path)">
        <b-tooltip
          :delay="500"
          multilinedNO
          position="is-right"
          type="is-dark"
          :label="copied == generator.path ? 'copied!' : 'copy path'"
        >
          <span style="opacity: 0.2; margin-right: 10px; margin-left: 10px">
            Posting 3 times a day automatically
            {{ generator.path }}
          </span>
        </b-tooltip>
      </p>

      <br style="clear: both" />

      <div class="tabs">
        <ul>
          <router-link
            tag="li"
            v-for="(tab, index) in tabs"
            :key="index"
            :to="{
              path: '/' + $route.params.bot + '/' + $route.params.generator + tab.path,
            }"
            active-class="is-active"
            exact
          >
            <a>
              <span class="icon is-small">
                <i :class="tab.icon" aria-hidden="true"></i>
              </span>
              <span>{{ tab.title }}</span>
            </a>
          </router-link>
        </ul>
      </div>

      <div v-if="generators && generator && !loadingGenerators && !loadingBot">
        <router-view :generator="generator" :generators="generators" :bot="bot" @specChange="specChange"></router-view>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    {{ bot }}
  </div>
</template>

<script>
import { MethodColors } from "@/common/utils.js";
import systemEndpoints from "@/system.spec.json";

const TABS = [
  /*
   {
    path: "",
    icon: "far fa-tachometer",
    title: "Overview",
  } 
  {
    path: "/use",
    icon: "far fa-envelope",
    title: "Messages",
  },
  
    {
    path: "/config",
    icon: "far fa-calendar",
    title: "Calendar?",
    isSystem: false,
  },
  
  */ {
    path: "/logs",
    icon: "far fa-retweet",
    title: "Text updates",
    isSystem: false,
  },
  /*
  {
    path: "/insights",
    icon: "far fa-search",
    title: "Search results",
    isSystem: false,
  },*/ {
    path: "/config",
    icon: "far fa-image",
    title: "Images",
    isSystem: false,
  },
  {
    path: "/code",
    icon: "far fa-file-alt",
    title: "Articles",
    isSystem: false,
  },
];
export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      systemEndpoints,
      MethodColors,
      generator: {},
      loadingBot: true,
      loadingGenerators: true,
      copied: null,
    };
  },
  computed: {
    title() {
      return this.$route.params.generator;
    },
    tabs() {
      if (this.generator.isSystem) {
        // If the generator is system, return all tabs
        return TABS;
      } else {
        // If not, return only the tabs that are not system-specific
        return TABS.filter((tab) => !tab.isSystem);
      }

      return TABS;
    },
  },
  methods: {
    loadAll(opt) {
      window.API.botProfileOwner(this.$route.params.bot, opt).then((bot) => {
        this.bot = bot;
        this.loadingBot = false;
        console.log("BOT!", bot, bot.id);
      });

      /*
      window.API.getBotGenerators(this.$route.params.bot, opt).then((generators) => {
        var generatorId = this.$route.params.generator;
        this.generators = generators;
        this.loadingGenerators = false;
        this.generator = generators.filter((i) => i.generatorId == generatorId)[0];

        if (!this.generator) {
          this.generator = systemEndpoints.filter((i) => i.generatorId == generatorId)[0];
          if (this.generator) {
            this.generator.isSystem = true;
          }
        }
      });*/
    },
    specChange() {
      console.log("REFRESH GENERATOR data.");
      this.loadAll({ noCache: 1 });
    },
    copyGenerator(txt) {
      navigator.clipboard.writeText(txt);
      this.copied = txt;

      setTimeout(
        function () {
          this.copied = null;
          console.log("reseted clipboard!");
        }.bind(this),
        6000
      );
    },
  },
  mounted() {
    this.loadAll({ noCache: 1 });
  },
};
</script>
